import React, { useEffect, useRef } from 'react';
//= Scripts
import navbarScrollEffect from "common/navbarScrollEffect";
//= Layout
import MainLayout from 'layouts/Main';
//= Components
import TopNav from 'components/Navbars/TopNav';
import Navbar from 'components/Navbars/SaasNav';
import AboutHeader from 'components/Saas/AboutHeader';
import Community from 'components/Saas/Community';
import Philosophy from 'components/Saas/Philosophy';
import ChooseUs from 'components/Saas/ChooseUs';
import Clients from 'components/Saas/Clients';
import Numbers from 'components/Saas/Numbers';
import Culture from 'components/Saas/Culture';
import Team from 'components/Saas/Team';
import Contact from 'components/Saas/Contact';
import Footer from 'components/Saas/Footer';
import Refund from 'components/Saas/Refund';

const PageAbout5 = () => {
  const navbarRef = useRef(null);

  useEffect(() => {
    navbarScrollEffect(navbarRef.current);
  }, [navbarRef]);

  return (
    <MainLayout>
      <TopNav style="5" />
      <Navbar navbarRef={navbarRef} />
      <main className="about-page style-5">
      <div
      style={{
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        margin: '0',
        padding: '20px',
        backgroundColor: '#f9f9f9',
        color: '#333',
      }}
    >
      <div
        style={{
          maxWidth: '800px',
          margin: 'auto',
          padding: '20px',
          background: '#fff',
          borderRadius: '5px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        }}
      >
        <h1 style={{ textAlign: 'center', color: '#2c3e50' }}>Refund Policy</h1>
        <p>
          At Woxox CRM, we are committed to providing the highest quality service and ensuring customer satisfaction. To
          ensure transparency, we have outlined our refund policy below:
        </p>

        <h2 style={{ color: '#2c3e50' }}>1. Free Trial Period</h2>
        <ul style={{ marginBottom: '1em', paddingLeft: '20px' }}>
          <li>
            Customers are encouraged to take advantage of our free trial period (3 days) to evaluate Woxox CRM’s features and
            compatibility with their business needs.
          </li>
          <li>Refunds are not applicable for services availed during the trial period.</li>
        </ul>

        <h2 style={{ color: '#2c3e50' }}>2. Subscription Plans</h2>
        <p>Refunds for subscription plans are subject to the following terms:</p>
        <h3 style={{ color: '#34495e', marginLeft: '20px' }}>a. Monthly Subscriptions:</h3>
        <ul style={{ marginBottom: '1em', paddingLeft: '40px' }}>
          <li>No refunds will be issued for monthly subscriptions once the payment has been processed.</li>
          <li>Customers can cancel their subscription at any time to avoid future charges.</li>
        </ul>
        <h3 style={{ color: '#34495e', marginLeft: '20px' }}>b. Annual Subscriptions:</h3>
        <ul style={{ marginBottom: '1em', paddingLeft: '40px' }}>
          <li>Refunds for annual subscriptions will be granted if requested within 3 days of the payment date.</li>
          <li>
            After 3 days, refunds are not available, but customers can continue using the service for the remainder of the
            subscription period.
          </li>
        </ul>

        <h2 style={{ color: '#2c3e50' }}>3. Cancellation Policy</h2>
        <ul style={{ marginBottom: '1em', paddingLeft: '20px' }}>
          <li>
            Customers can cancel their subscription at any time through{' '}
            <a href="mailto:support@woxox.com" style={{ color: '#3498db', textDecoration: 'none' }}>
              support@woxox.com
            </a>
            .
          </li>
          <li>
            Cancellation will prevent future billing but does not qualify for a refund unless it meets the criteria outlined
            in Section 2.
          </li>
        </ul>

        <h2 style={{ color: '#2c3e50' }}>4. Refund Eligibility</h2>
        <p>Refunds will only be processed under the following conditions:</p>
        <ul style={{ marginBottom: '1em', paddingLeft: '20px' }}>
          <li>Duplicate payment made by the customer.</li>
          <li>
            Service disruptions caused by Woxox CRM for a continuous period exceeding 72 hours, with no resolution provided.
          </li>
        </ul>

        <h2 style={{ color: '#2c3e50' }}>5. Refund Request Process</h2>
        <p>To request a refund, customers must:</p>
        <ul style={{ marginBottom: '1em', paddingLeft: '20px' }}>
          <li>Submit a refund request via email to support@woxox.com with the following details:</li>
          <ul style={{ marginBottom: '1em', paddingLeft: '40px' }}>
            <li>Full name</li>
            <li>Registered email address</li>
            <li>Reason for the refund request</li>
            <li>Proof of payment (e.g., transaction ID or receipt)</li>
          </ul>
        </ul>
        <p>
          Refund requests will be reviewed within 7 business days, and eligible refunds will be processed within 14 business
          days of approval.
        </p>

        <h2 style={{ color: '#2c3e50' }}>6. Non-Refundable Services</h2>
        <p>The following are non-refundable:</p>
        <ul style={{ marginBottom: '1em', paddingLeft: '20px' }}>
          <li>Customization or integration services provided by Woxox CRM.</li>
          <li>Add-ons or third-party services purchased through Woxox CRM.</li>
          <li>Fees related to onboarding, training, or consultation.</li>
        </ul>

        <h2 style={{ color: '#2c3e50' }}>7. Amendments to the Policy</h2>
        <p>
          Woxox CRM reserves the right to modify this refund policy at any time. Customers will be notified of significant
          changes via email or through updates on our website.
        </p>

        <h2 style={{ color: '#2c3e50' }}>Contact Us</h2>
        <p>If you have any questions about our refund policy, please reach out to our support team at:</p>
        <ul style={{ marginBottom: '1em', paddingLeft: '20px' }}>
          <li>
            <strong>Email:</strong>{' '}
            <a href="mailto:info@woxox.com" style={{ color: '#3498db', textDecoration: 'none' }}>
              info@woxox.com
            </a>
          </li>
        </ul>
        <p>Thank you for choosing Woxox CRM.</p>
      </div>
    </div>
      </main>
      <Footer noWave />
    </MainLayout>
  )
}

export const Head = () => {
  return (
    <>
      <title>Iteck - About 5</title>
      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />
    </>
  )
}

export default PageAbout5;